<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      d="M1.475 19.525V3.475H7a.475.475 0 1 0 0-.95H1A.475.475 0 0 0 .525 3v17c0 .262.213.475.475.475h13a.475.475 0 1 0 0-.95H1.475z"
    />
    <path d="M7.119 7.922l-6-1.546a.475.475 0 1 0-.238.92l6 1.546a.475.475 0 1 0 .238-.92z" />
    <g>
      <path
        d="M20.475 20a.475.475 0 0 1-.475.475H7A.475.475 0 0 1 6.525 20V1c0-.262.213-.475.475-.475h13c.262 0 .475.213.475.475v19zm-.95-.475V1.475H7.475v18.05h12.05z"
      />
      <path
        d="M7.127 6.379a.475.475 0 1 0-.254.915l6.5 1.81a.475.475 0 0 0 .254 0l6.5-1.81a.475.475 0 1 0-.254-.915L13.5 8.153 7.127 6.379z"
      />
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
